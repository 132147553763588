import PropTypes from 'prop-types';
import './Col.scss';

const Col = ({ children, span }) => {
    const styles = {
        flex: '0 0 ' + span / 24 * 100 + '%',
        maxWidth: span / 24 * 100 + '%'
    };

    return (
        <div className='col' style={styles}>
            {children}
        </div>
    );
};

Col.propTypes = {
    children: PropTypes.any.isRequired,
    span: PropTypes.number
};

export default Col;
