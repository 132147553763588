import PropTypes from 'prop-types';
import './Nav.scss';

const Nav = ({ logo, children }) => {

    let links = children.map(child => <li key={child.props.children}>{child}</li>);

    return (
        <nav>
            <img className='nav-logo' src={logo} alt='logo' />
            <ul className='nav-links'>
                {links}
            </ul>
        </nav>
    );
};

Nav.propTypes = {
    logo: PropTypes.string.isRequired,
    children: PropTypes.any
};

export default Nav;
