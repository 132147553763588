import PropTypes from 'prop-types';
import './Button.scss';

const Button = ({ children, goto, style }) => {
    return (
        <a className='btn' href={goto} style={style}>{children}</a>
    );
};

Button.propTypes = {
    children: PropTypes.any.isRequired,
    goto: PropTypes.string,
    style: PropTypes.object
};

export default Button;
