import PropTypes from 'prop-types';
import './LeftBar.scss';

const LeftBar = ({ children }) => {
    return (
        <div className='left-bar'>
            {children}
        </div>
    );
};

LeftBar.propTypes = {
    children: PropTypes.any
};

export default LeftBar;
