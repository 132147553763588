import PropTypes from 'prop-types';
import './Image.scss';
import { RiExternalLinkLine } from 'react-icons/ri';

const Image = ({ src, logo, width, height, link, style, alt = 'image' }) => {
    
    const content = 
        <>
            <img className='image' src={src} alt={alt} width={width} height={height}/>
            {logo && <img className='logo' src={logo} alt='logo'/>}
        </>
        
    ;
    
    if (link) {
        return (
            <a href={link}>
                <div className='image-container'>
                    <RiExternalLinkLine className='external' fill='url(#blue-gradient)'/>
                    {content}
                </div>
            </a>
        );
    }

    return  (
        <div className='image-container' style={style}>
            {content}
        </div>);
};

Image.propTypes = {
    src: PropTypes.string.isRequired,
    logo: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    style: PropTypes.object,
    link: PropTypes.string
};

export default Image;
