import PropTypes from 'prop-types';
import './Section.scss';

const Section = ({ title, children, id }) => {
    return (
        <div className='section' id={id}>
            <h1 className='section-title'>{title}</h1>
            {children}
        </div>
    );
};

Section.propTypes = {
    children: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.string
};

export default Section;
