import './ContactForm.scss';

const ContactForm = () => {
    return (
        <div className='contact-form'>
            <input className='input input-name' autoComplete='on' type='text' placeholder='First Name' />
            <input className='input input-name' autoComplete='on' type='text' placeholder='Last Name' />
            <input className='input' autoComplete='on' type='email' placeholder='E-Mail' />
            <textarea className='input' type='text' placeholder='Message' rows={7} />
        </div>
    );
};

export default ContactForm;
