import PropTypes from 'prop-types';
import './RightBar.scss';

const RightBar = ({ children }) => {
    return (
        <div className='right-bar'>
            {children}
        </div>
    );
};

RightBar.propTypes = {
    children: PropTypes.any
};

export default RightBar;
