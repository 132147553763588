import PropTypes from 'prop-types';
import './Dots.scss';

const Dots = ({ lgOffset = [ '5rem', '5rem' ] }) => {
    return (
        <div className='dots' style={{ marginTop: lgOffset[0], marginBottom: lgOffset[1] }}>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
        </div>
    );
};

Dots.propTypes = {
    lgOffset: PropTypes.array
};

export default Dots;
