import PropTypes from 'prop-types';
import './Text.scss';

const Text = ({ title, subtitle, children }) => {


    return (
        <div className='text'>
            <section>
                <h2 className='text-title'>{title}</h2>
                {subtitle && <h3 className='text-subtitle'>{subtitle}</h3>}
            </section>
            {children}
        </div>
    );
};

Text.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.any,
    children: PropTypes.any.isRequired
};

export default Text;
