import { useEffect, useState } from 'react';
import './Introduction.scss';

const Introduction = () => {

    const [ count, setCount ] = useState(0);
    const [ key, setKey ] = useState(0);
    const [ currentTitle, setCurrentTitle ] = useState('');
    const [ titles, setTitles ] = useState(
        [ 
            'Web Developer', 
            'Designer', 
            'CS Student',
            'Tech Enthusiast',
            'Server Administrator'
        ]
    );

    let speed = 200;

    useEffect(() => {
        let target = titles[key];

        if (count < target.length) {
            setCurrentTitle(currentTitle + target.charAt(count)); 
            setTimeout(() => {
                setCount(count + 1);
            }, speed);
        } else if (currentTitle == target) {
            setTimeout(() => {
                setCurrentTitle(currentTitle.slice(0, -1));
                setCount(count + 1);
            }, 3000);
        } else if (currentTitle == '') {
            setTimeout(() => {
                setKey((key + 1) % titles.length);
                setCount(0);
            }, 100);
        } else {    
            setTimeout(() => {
                setCurrentTitle(currentTitle.slice(0, -1));
                setCount(count + 1);
            }, 50);
        }

    }, [ count ]);

    return (
        <>
            <div className='intro-container'>
                <div className='pre-name'>
                    <span>Hi, My Name Is</span>
                    <span>And I&apos;m a</span>
                </div>
                <div className='name'>
                    <span className='me'>Aloys Riswick</span>
                    <span className='function-title'>{currentTitle}</span>
                </div>
            </div>
        </>
    );
};

export default Introduction;
