import Button from './components/Button';
import Nav from './components/Nav';
import Introduction from './components/Introduction';
import Section from './components/Section';
import Text from './components/Text';
import Row from './components/Row';
import Col from './components/Col';
import Image from './components/Image';
import Dots from './components/Dots';
import LeftBar from './components/LeftBar';

import {
    SiAdobe,
    SiApache,
    SiBootstrap,
    SiCsharp,
    SiCss3,
    SiEslint,
    SiGithub,
    SiHtml5,
    SiJavascript,
    SiLinkedin,
    SiNpm,
    SiReact,
    SiUbuntu,
    SiVisualstudiocode,
    SiYoutube
} from 'react-icons/si';
import { AiOutlineAntDesign } from 'react-icons/ai';
import { FaEnvelope } from 'react-icons/fa';
import { CgScrollV } from 'react-icons/cg';

import './App.scss';
import RightBar from './components/RightBar';
import ContactForm from './components/ContactForm';

function App() {
    return (
        <div>
            <div className='App' id='home'>
                <div style={{ minHeight: '100vh' }}>
                    <Nav logo='/images/logo.webp'>
                        <a href='/#home'>home</a>
                        <a href='/#my-work'>My work</a>
                        <a href='/#about'>About</a>
                        <a href='/#contact'>Contact</a>
                    </Nav>
                    <img className='stealth-logo' src='/images/logo.webp' alt='logo'></img>
                    <Introduction></Introduction>
                    <Button goto='/#my-work' style={{ marginTop: '5rem' }}>
                        Check out my work
                    </Button>
                    <CgScrollV fill='url(#blue-gradient)' stroke='url(#blue-gradient)' className='scroll-indicator' />
                </div>
                <Section title='my work' id='my-work'>
                    <Row>
                        <Col>
                            <Text
                                title='envision'
                                subtitle={<a href='https://envision.riswick.net'>envision.riswick.net</a>}
                            >
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates consequuntur nobis
                                    molestias magnam sequi esse, odio sunt. Quia amet commodi quo dolor? Quisquam magni
                                    fugiat fuga quos atque itaque possimus.
                                </p>
                                <Button goto='https://envision.riswick.net' style={{ marginRight: 'auto' }}>
                                    Go to project
                                </Button>
                            </Text>
                        </Col>
                        <Col>
                            <Image
                                src='images/envision.webp'
                                link='https://envision.riswick.net'
                                logo='images/envision-logo.webp'
                                alt='envision'
                                width='100%'
                            ></Image>
                        </Col>
                    </Row>
                    <Dots></Dots>
                    {/* <svg
                        className='stealth-streak'
                        style={{ transform: 'translate(-50%, -50%) translate(0, -8rem)' }}
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 1924.577 3171.434'
                    >
                        <path
                            id='Path_6'
                            data-name='Path 6'
                            d='M23.436,529.791,1948.013,2447.48V3701.225L23.506,1778.813Z'
                            transform='translate(-23.436 -529.791)'
                        />
                    </svg> */}
                    <div className='streak'></div>
                    <Row>
                        <Col>
                            <Text title='pinite' subtitle={<a href='https://pinite.net'>pinite.net</a>}>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates consequuntur nobis
                                    molestias magnam sequi esse, odio sunt. Quia amet commodi quo dolor? Quisquam magni
                                    fugiat fuga quos atque itaque possimus.
                                </p>
                                <Button goto='https://pinite.net' style={{ marginRight: 'auto' }}>
                                    Go to project
                                </Button>
                            </Text>
                        </Col>
                        <Col>
                            <Image
                                src='images/pinite.webp'
                                link='https://pinite.net'
                                logo='images/pinite-logo.svg'
                                alt='pinite'
                                width='100%'
                            ></Image>
                        </Col>
                    </Row>
                </Section>
                <Section title='about' id='about'>
                    <Row>
                        <Col span={16}>
                            <Text title='background'>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates consequuntur nobis
                                    molestias magnam sequi esse, odio sunt. Quia amet commodi quo dolor? Quisquam magni
                                    fugiat fuga quos atque itaque possimus.
                                </p>
                                <LeftBar>
                                    <SiGithub fill='url(#blue-gradient)' />
                                    <SiLinkedin fill='url(#blue-gradient)' />
                                    <SiYoutube fill='url(#blue-gradient)' />
                                </LeftBar>
                            </Text>
                        </Col>
                        <Col span={8}>
                            <Image src='images/profile.svg' alt='profile' width='100%'></Image>
                        </Col>
                    </Row>
                    <Dots lgOffset={[ '9.75rem', '5rem' ]}></Dots>
                    <Row>
                        <Col span={18}>
                            <Text title='Skills'>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates consequuntur nobis
                                    molestias magnam sequi esse, odio sunt. Quia amet commodi quo dolor? Quisquam magni
                                    fugiat fuga quos atque itaque possimus.
                                </p>
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <RightBar>
                            <SiReact fill='url(#blue-gradient)' />
                            <SiHtml5 fill='url(#blue-gradient)' />
                            <SiCss3 fill='url(#blue-gradient)' />
                            <SiJavascript fill='url(#blue-gradient)' />
                            <SiAdobe fill='url(#blue-gradient)' />
                            <SiApache fill='url(#blue-gradient)' />
                            <AiOutlineAntDesign fill='url(#blue-gradient)' />
                            <SiBootstrap fill='url(#blue-gradient)' />
                            <SiVisualstudiocode fill='url(#blue-gradient)' />
                            <SiNpm fill='url(#blue-gradient)' />
                            <SiCsharp fill='url(#blue-gradient)' />
                            <SiEslint fill='url(#blue-gradient)' />
                            <SiUbuntu fill='url(#blue-gradient)' />
                        </RightBar>
                    </Row>
                </Section>
                <Section title='contact' id='contact'>
                    <Row>
                        <Col span={8}>
                            <FaEnvelope className='stealth-mail' />
                        </Col>
                        <Col span={16}>
                            <ContactForm></ContactForm>
                            <Button style={{ float: 'right', margin: '5rem 0' }} goto='/#contact'>
                                send message
                            </Button>
                        </Col>
                    </Row>
                </Section>
                <svg width='0' height='0'>
                    <linearGradient id='blue-gradient' x1='100%' y1='100%' x2='0%' y2='0%'>
                        <stop stopColor='#53e4b9' offset='0%' />
                        <stop stopColor='#2fac35' offset='100%' />
                    </linearGradient>
                </svg>
            </div>
            <div className='triangle'></div>
        </div>
    );
}

export default App;
